<template>
	<div class="article__view">
		<div class="header">
			<h2>Publier le site sur l'annuaire</h2>
		</div>
		<div class="control">
			<v-btn v-if="site.user > 0" color="#272727" class="white--text" @click="retour()" tile x-large>
				Retour
			</v-btn>
			<v-btn v-if="annuaireInscription.id > 0" color="green" class="white--text" :loading="loading" @click="sendWp()" tile x-large :disabled="annuaireInscription.status == 'publish'" >
				Publier
			</v-btn>
		</div>
		<div class="displayer">
			<div class="right">
				<div class="article__card">
					<div class="article">
						<b>Annuaire</b>
						<p><a :href="getBaseUrl(annuaireInscription.annuaire.url)" target="_blank">{{ annuaireInscription.annuaire.name }}</a></p>
						<br>
						<b>Description</b>
						<p>{{ annuaireInscription.content }}</p>
						<br>
						<v-img :src="urlImage"></v-img>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { utils } from '../utils.js'

export default {
	name: 'AnnuaireInscriptionSend',
	data() {
		return {
			annuaireInscription: {
				id: null,
				annuaire: {
					id: null,
					name: '',
					url: ''
				},
				site: null,
				content: '',
				status: 'draft',
				file: null
			},
			site: {
				id: 0,
				user: 0
			},
			urlImage: '', // l'url qui sert uniquement à l'affichage (si l'article possède une image ou non)
			toDelete: {},
			dialog: false,

			loading: false,
			loadingDelete: false,
			loadingUpload: false
		}
	},
	methods: {
		...mapActions("global", [
			"displayFileAction",
			"getAnnuaireInscriptionAction",
			"updateAnnuaireInscriptionAction",
			"sendAnnuaireInscriptionAction",
			"getSiteAction"
		]),
		...mapMutations("global", [
			"addSuccess",
			"addErrors"
		]),
        getBaseUrl(url) {
            let urlObject = new URL(url);
            return urlObject.origin
        },
		retour() {
			this.$router.push({ path: '/user/' + this.site.user });
		},
		sendWp() {
			const _this = this;
			this.loading = true;

			this.sendAnnuaireInscriptionAction(this.annuaireInscription.id)
			.then(() => {
				return this.updateAnnuaireInscriptionAction({
					annuaireInscription: this.annuaireInscription.id,
					status: 'publish',
					content: this.annuaireInscription.content,
					file: this.annuaireInscription.file
				})
			})			
			.then(() => {
				_this.addSuccess("Le site a été inscrit sur l'annuaire.")
				_this.retour()
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loading = false
			})
		},
		loadAnnuaireInscription() {
			const _this = this
			this.getAnnuaireInscriptionAction(this.$route.params.annuaireInscriptionId)
			.then((res) => {
				_this.annuaireInscription.id = res.data.id
				_this.annuaireInscription.titre = res.data.titre
				_this.annuaireInscription.content = res.data.content
				_this.annuaireInscription.annuaire.id = res.data.annuaire.id
				_this.annuaireInscription.annuaire.name = res.data.annuaire.name
				_this.annuaireInscription.annuaire.url = res.data.annuaire.url
				_this.annuaireInscription.status = res.data.status
				_this.site.id = res.data.site
				_this.annuaireInscription.file = res.data.file
				_this.loadFile()

				return this.getSiteAction(res.data.site)
			})
			.then((res) => {
				_this.site.user = res.data.user
			})

		},
		loadFile() {
			if (this.annuaireInscription.file < 1) {
				return
			}

			const _this = this
			this.displayFileAction(this.annuaireInscription.file)
			.then((res) => {
				_this.urlImage = res
			})
		},
	},
	created() {
		this.loadAnnuaireInscription()
	}
}
</script>

<style lang="scss" scoped>
.article__view {
	min-height: 100vh;

	.header {
		display: flex;
		align-items: center;
		padding: 20px;
		border-bottom: 1px solid #dbdbdb;

		h2 {
			font-weight: normal;
			margin-bottom: 20px;
			width: 100%;
			margin: 0;

			span {
				font-size: 16px;
				color: #969696;
			}
		}
	}

	.control {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 100;

		.v-btn {
			width: 50%;
		}
	}

	.displayer {
		display: flex;
		height: 100%;

		.left {
			width: 25%;
			min-height: 100vh;
			border-right: 1px solid #dbdbdb;
		}

		.right {
			width: 75%;
			padding: 1% 2% 5% 2%;
			height: fit-content;

			.article__card {
				padding: 10px;

				h4 {
					color: #c1c1c1;
					font-weight: normal;
				}

				.article {
					margin-top: 2%;

					.swicth__status {
						display: flex;
						align-items: center;

						h3 {
							margin-right: 10px;
						}
					}

					.info__image {
						margin-top: 15px;
					}

					.upload__image {
						margin-top: 25px;
						.displayer {
							display: flex;
							gap: 50px;
						}

						.grid__gallery {
							width: 50%;
							border-right: 2px dashed #c7c7c7;
							min-height: 250px;

							.row {
								padding: 10px;
							}

							.gallery {
								cursor: pointer;
								position: relative;

								.selected {
									position: absolute;
									top: 0;
									right: 0;
									border-radius: 50%;
									background: green;
									z-index: 2;
									width: 25px;
									height: 25px;
									display: flex;
									align-items: center;
									justify-content: center;

									i {
										font-size: 15px;
									}
								}
							}
						}

						.upload__gallery {
							width: 50%;
						}

						.loading__file {
							margin-top: 50px;
						}
					}

				}
			}
		}
	}
}
</style>